import { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Snackbar, SnackbarContent } from "@mui/material";
import TrainingCandidatesAPI from "../../../../../store/api/trainingCandidatesApi";
import MainContainer from "../../../../UI/MainContainer";
import AuthenticatedNotFound404 from "../../../../pages/not-found-404/AuthenticatedNotFound404";
import InformationSection from "../../../../UI/InformationSection";
import BreadCrumb from "../../../../UI/BreadCrumb";
import TrainingCandidateDetailsType from "../../../../../types/TrainingCandidateDetails";
import MessageSection from "../../../../UI/MessageSection";
import { TrainingCandidatesContext } from "../../../../../store/TrainingCandidatesContext";
import MainFooter from "../../../../UI/MainFooter";
import { format } from "date-fns";
import { getValueFromList } from "../../../../../utils/getValue";

const TrainingCandidateDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const parentContainer = useRef<HTMLDivElement>(null);
  const informationContiner = useRef<HTMLElement>(null);

  const { getTrainingCandidateDetails, updateStatus, getCountryList } = TrainingCandidatesAPI();
  const [countryList, setCountryList] = useState<[string, string][]>([]);
  const { setResponseData, responseData, referralList, organizationList, setLoading } = useContext(TrainingCandidatesContext);
  const [trainingCandidate, setTrainingCandidate] = useState<TrainingCandidateDetailsType>();
  const [isUrlInvalid, setUrlInvalid] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });

  useEffect(() => {
    onGetCountryList();
  }, []);

  useEffect(() => {
    onGetTrainingCandidateDetails();
  }, [countryList]);

  const onGetCountryList = () => {
    getCountryList(
      async (response: Response) => {
        if (response.ok) {
          const result = await response.json();
          const countryTuples: [string, string][] = result.map(
            (country: { country__id: string; name: string }) => [country.country__id, country.name]
          );
          setCountryList(countryTuples);
        } else {
          console.error("Failed to fetch country list");
        }
      }
    );
  };

  const onGetTrainingCandidateDetails = () => {
    getTrainingCandidateDetails(parseInt(id), async (
      response: Response,
      _trainingCandidate: TrainingCandidateDetailsType
    ) => {
      if (response.status === 404) {
        setUrlInvalid(true);
        return;
      }

      const processedTrainee = {
        ..._trainingCandidate,
        referral: getValueFromList(_trainingCandidate.referral, referralList),
        country: getValueFromList(_trainingCandidate.country, countryList)
      };

      setTrainingCandidate(processedTrainee);
    });
  };

  const updateStatusHandler = (_id: number, _status: string) => {
    updateStatus(async (response) => updateProcessResponse(response, _id, _status), _id, _status);
  };

  const updateProcessResponse = async (response: Response, _id: number, _status: string) => {
    if (response.ok) {
      const result = await response.json();
      const newData = [...responseData];
      const index = responseData.findIndex((data) => data.id === _id);
      newData[index] = {
        first_name: result.first_name,
        last_name: result.last_name,
        email: result.email,
        phone: result.phone,
        clinic: result.clinic,
        supervisor: result.supervisor,
        referral: result.referral,
        contact_date: result.contact_date,
        status: _status,
        id: result.id,
      };
      setResponseData(newData);
      setLoading(false);
      setSnackbar({
        show: true,
        message: t("status_updated_successfully"),
      });
    }
  };

  if (isUrlInvalid) {
    return (
      <MainContainer>
        <AuthenticatedNotFound404 message={t("not_found_url__message")} />
      </MainContainer>
    );
  }

  const getFormattedDate = () => {
    return format(new Date(trainingCandidate!.contactDate), "dd/MM/yyyy kk:mm");
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      <main ref={parentContainer} className="alignment mb-8">
        {trainingCandidate && (
          <>
            <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-27">{trainingCandidate.fullName}</h3>
                <BreadCrumb
                  className="mt-[2px] mx-2"
                  availableLinks={[
                    { title: t("contact") },
                    { title: t("training_candidates"), path: "/training-candidates" },
                    { title: trainingCandidate.fullName },
                  ]}
                />
              </div>
            </header>
            <div className="flex flex-col">
              <section ref={informationContiner}>
                <InformationSection
                  className="mt-4"
                  title={t("training_candidate_information")}
                  information={[
                    {
                      label: t("full_name"),
                      value: trainingCandidate.fullName,
                    },
                    {
                      label: t("email"),
                      value: trainingCandidate.email,
                    },
                    {
                      label: t("phone"),
                      value: trainingCandidate.phone,
                    },
                    {
                      label: t("clinic"),
                      value: trainingCandidate.clinic,
                    },
                    {
                      label: t("supervisor"),
                      value: trainingCandidate.supervisor,
                    },
                    {
                      label: t("address_postal_code"),
                      value: trainingCandidate.address + " - " + trainingCandidate.postalCode,
                    },
                    {
                      label: t("country"),
                      value: trainingCandidate.country,
                    },
                    {
                      label: t("city"),
                      value: trainingCandidate.city,
                    },
                    {
                      label: t("hear_about_us"),
                      value: trainingCandidate.referral,
                    },
                  ]}
                />
                <MessageSection
                  className="mt-6"
                  title={t("message")}
                  message={trainingCandidate.message}
                  date={getFormattedDate()}
                  status={trainingCandidate.status}
                  onSave={(newStatus) => updateStatusHandler(parseInt(id), newStatus)}
                />
              </section>
              <MainFooter />
            </div>
          </>
        )}
      </main>
    </MainContainer>
  );
};

export default TrainingCandidateDetails;
